import { Neighbor, Property, VipRank } from '@services/hafh/types/generated';
import { FREE_PLAN_KIND } from '@utils/constants';
import { removeRedirectUrlParams } from '@utils/redirect';
import { isEmpty } from '@utils/utils';
import moment from 'moment-timezone';

export const inFirstMonth = (neighbor: Neighbor) =>
  moment().startOf('month').isSame(neighbor.trial_month);

export const checkIsPropertyFavored = (
  favoriteProperties: Property[],
  propertyId: number
) =>
  favoriteProperties.some(
    (favoriteProperty) => favoriteProperty.id === propertyId
  );

export const calculateCoinBonusForVip = (
  vipRank: VipRank | undefined,
  coin: number
) => {
  if (!vipRank || !['gold', 'platinum'].includes(vipRank) || coin < 300) {
    return 0;
  }

  if (coin < 500) {
    return Math.round(coin * 0.03);
  }

  return Math.round(coin * 0.05);
};

export const getReferralCodeExpiryMoment = (neighbor: Neighbor) =>
  moment(neighbor.created_at).add(3, 'days');

export const getNeighborSubscribed = (neighbor?: Neighbor) =>
  !isEmpty(neighbor) && neighbor?.neighbor_plans?.[0].kind !== FREE_PLAN_KIND;

export const getNeighborOnFreePlan = (neighbor?: Neighbor) =>
  !isEmpty(neighbor) && neighbor?.neighbor_plans?.[0].kind === FREE_PLAN_KIND;

export const logoutCleanup = () => {
  localStorage.removeItem('hideCoinPackDialog');
  localStorage.removeItem('hideCoinPackBanner');
  sessionStorage.removeItem('hideInviteStickyBanner');
  removeRedirectUrlParams();
};
